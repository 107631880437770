import React, { useEffect, useCallback, useState, useRef } from 'react';
import ReactPlayer from 'react-player';

import { createContext, useContext } from 'react';

const AudioPlayerContext = createContext({});

const useAudioPlayerContext = () => useContext(AudioPlayerContext);

const AudioPlayerProvider = (props) => {
  const progressInterval = 1000;
  const [trackIdx, setTrackIdx] = useState(null);
  const [trackUrl, setTrackUrl] = useState('');
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLooping, setIsLooping] = useState(false);
  const [trackDuration, setTrackDuration] = useState(null);
  const [totalTrackDuration, setTotalTrackDuration] = useState(null);
  const [seeking, setSeeking] = useState(false);

  let reactPlayerRef = useRef(null);

  const onReady = useCallback(() => {
    setIsPlaying(true);
  }, []);

  useEffect(() => {
    resetPlaylist();
  }, [props.tracks]);

  const resetPlaylist = () => {
    setTrackIdx(null);
    setTrackUrl('');
    setIsPlaying(false);
    setIsLooping(false);
    setTrackDuration(null);
    setTotalTrackDuration(null);
  };

  const onPlay = useCallback(() => {
    if (trackUrl) {
      setIsPlaying(true);
    } else {
      const { category, filename } = props.tracks[0];
      setTrackUrl(`/audio/${category}/${filename}`);
      setTrackIdx(0);
    }
  }, [props.tracks, trackUrl]);

  const onProgress = useCallback((metadata) => {
    if (!seeking) {
      setTrackDuration(metadata.playedSeconds);
    }
  }, []);

  const onDuration = useCallback((duration) => {
    setTotalTrackDuration(duration);
    setTrackDuration(0);
  }, []);

  const onPause = useCallback(() => {
    setIsPlaying(false);
  }, []);

  const onNextTrack = useCallback(() => {
    if (trackIdx !== null) {

      let nextTrackIdx = trackIdx + 1;

      // if next track undefined, start playlist beginning
      if (!props.tracks[nextTrackIdx]) {
        nextTrackIdx = 0;
      }

      const { category, filename } = props.tracks[nextTrackIdx];
      setTrackIdx(nextTrackIdx);
      setTrackUrl(`/audio/${category}/${filename}`);
    }
  }, [props.tracks, trackIdx]);

  const onPrevTrack = useCallback(() => {
    if (trackIdx !== null) {

      let prevTrackIdx = trackIdx - 1;

      // if prev track undefined, start playlist from end
      if (!props.tracks[prevTrackIdx]) {
        prevTrackIdx = props.tracks.length - 1;
      }

      const { category, filename } = props.tracks[prevTrackIdx];
      setTrackIdx(prevTrackIdx);
      setTrackUrl(`/audio/${category}/${filename}`);
    }
  }, [props.tracks, trackIdx]);

  const onEnded = useCallback((e) => {
    let nextTrackIdx = trackIdx + 1;

    // if next track undefined, end of playlist reached
    if (!props.tracks[nextTrackIdx]) {
      return resetPlaylist();
    }

    setTimeout(() => {
      const { category, filename } = props.tracks[nextTrackIdx];
      setTrackIdx(nextTrackIdx);
      setTrackUrl(`/audio/${category}/${filename}`);
    }, 3000);
  }, [trackIdx]);

  const onError = useCallback((e) => {
    console.log('onError: ', e);
  }, []);

  const handleSeekMouseDown = () => {
    setSeeking(true);
  };

  const handleSeekChange = e => {
    setTrackDuration(parseFloat(e.target.value));
  };

  const handleSeekMouseUp = e => {
    setSeeking(false);
    reactPlayerRef.seekTo(parseFloat(e.target.value));
  };
  
  const context = {
    onPlay,
    onPause,
    onNextTrack,
    onPrevTrack,


    progressInterval,
    trackUrl,
    isPlaying,
    isLooping,
    trackDuration,
    totalTrackDuration,



    setIsPlaying,
    setIsLooping,
    setTrackUrl,
    setTrackIdx,

    handleSeekMouseUp,
    handleSeekChange,
    handleSeekMouseDown
  };

  return (
    <AudioPlayerContext.Provider value={context}>
      {props.children}

      <ReactPlayer
        ref={(el) => reactPlayerRef = el}
        progressInterval={progressInterval}
        url={trackUrl}
        onProgress={onProgress}
        playing={isPlaying}
        onDuration={onDuration}
        onReady={onReady}
        style={{
          display: "none"
        }}
        onEnded={onEnded}
        onError={onError}
      />
    </AudioPlayerContext.Provider>
  );
};

export { AudioPlayerProvider as default, useAudioPlayerContext };