export default [
	{
		path: require('../../assets/images/slides/image1.jpg').default
	},
	{
		path: require('../../assets/images/slides/image2.jpg').default
	},
	{
		path: require('../../assets/images/slides/image3.jpg').default
	},
	{
		path: require('../../assets/images/slides/image4.jpg').default
	},
	{
		path: require('../../assets/images/slides/image5.jpg').default
	},
	{
		path: require('../../assets/images/slides/image6.jpg').default
	},
	{
		path: require('../../assets/images/slides/image7.jpg').default
	},
	{
		path: require('../../assets/images/slides/image8.jpg').default
	},
	{
		path: require('../../assets/images/slides/image9.jpg').default
	},
	{
		path: require('../../assets/images/slides/image10.jpg').default
	},
	{
		path: require('../../assets/images/slides/image11.jpg').default
	},
	{
		path: require('../../assets/images/slides/image12.jpg').default
	},
	{
		path: require('../../assets/images/slides/image13.jpg').default
	},
	{
		path: require('../../assets/images/slides/image14.jpg').default
	},
	{
		path: require('../../assets/images/slides/image15.jpg').default
	},
	{
		path: require('../../assets/images/slides/image16.jpg').default
	},
	{
		path: require('../../assets/images/slides/image17.jpg').default
	},
	{
		path: require('../../assets/images/slides/image18.jpg').default
	},
	{
		path: require('../../assets/images/slides/image19.jpg').default
	},
	{
		path: require('../../assets/images/slides/image20.jpg').default
	},
];