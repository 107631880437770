import React from 'react';
import { Dropdown, Container, Navbar, Nav as BNav } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { Youtube } from 'react-bootstrap-icons';
import './Nav.css';

const Nav = () => {
  return (
    <Navbar className="Navbar" bg="dark" variant="dark" expand="md" collapseOnSelect={true}>
      <Container>
        <LinkContainer to="/">
          <Navbar.Brand className="text-white-50">Visions in Words</Navbar.Brand>
        </LinkContainer>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <BNav className="ml-auto mr-md-5 align-items-md-center">
            <LinkContainer to="/">
              <BNav.Link>Home</BNav.Link>
            </LinkContainer>

            <Dropdown as={BNav.Item} className="Navbar-dropdown mx-md-3">
              <Dropdown.Toggle className="d-flex align-items-center" as={BNav.Link}>Listen</Dropdown.Toggle>

              <Dropdown.Menu className="bg-dark">
                <LinkContainer to="/playlist/daily">
                  <Dropdown.Item className="text-light m-0 pt-2 pb-2">Daily</Dropdown.Item>
                </LinkContainer>

                <LinkContainer to="/playlist/humans">
                  <Dropdown.Item className="text-light m-0 pt-2 pb-2">Humans</Dropdown.Item>
                </LinkContainer>
                <LinkContainer to="/playlist/animals">
                  <Dropdown.Item className="text-light m-0 pt-2 pb-2">Animals</Dropdown.Item>
                </LinkContainer>
                <LinkContainer to="/playlist/water">
                  <Dropdown.Item className="text-light m-0 pt-2 pb-2">Water</Dropdown.Item>
                </LinkContainer>
                <LinkContainer to="/playlist/nature">
                  <Dropdown.Item className="text-light m-0 pt-2 pb-2">Nature</Dropdown.Item>
                </LinkContainer>
              </Dropdown.Menu>
            </Dropdown>

            <BNav.Item className="Navbar-youtube">
              <hr className="Navbar-hr my-2 bg-light" />
              <a href="https://www.youtube.com/channel/UCB6erukS4pR_KMq4cmrk-EQ" target="_blank" alt="Visions in Words YouTube">
                <Youtube className="Navbar-youtubeIcon" />
              </a>
            </BNav.Item>
          </BNav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Nav;