import React, { useState } from 'react';
import './Footer.css';

const Footer = () => {
  const [currentYear] = useState(new Date().getFullYear());
  
  return (
    <footer className="Footer d-flex bg-dark justify-content-center align-items-center">
      <p className="text-center mb-0 text-white-50">&copy; {currentYear} Jake Hurt Hughes</p>
    </footer>
  );
};

export default Footer;