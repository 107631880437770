import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Carousel } from 'react-bootstrap';
import AudioPlayer from '../../components/AudioPlayer';
import * as tracksApi from '../../api/tracks';
import Track from '../../components/Track';
import { ListGroup } from 'react-bootstrap';
import carouselImages from './slidesImports';
import Footer from '../../components/Footer';
import AudioPlayerProvider from '../../components/AudioPlayer/AudioPlayerProvider';
import './Playlist.css';

const Playlist = () => {
  const [trackList, setTrackList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const params = useParams();

  useEffect(() => {

  }, [params.category]);

  useEffect(() => {
    async function getTracks() {
      setIsLoading(true);
      try {
        const data = await tracksApi.getTracks({ category: params.category });
        const tracks = data.tracks
          .filter((track) => track.indexOf('webalizer'))
          .map((track) => ({
            displayName: track.split('.')[0],
            filename: track,
            category: params.category
          }))
          .sort((a, b) => {
            return a.displayName - b.displayName;
          });

          setTimeout(() => {
            setTrackList(tracks);
            setIsLoading(false);
          }, 500);
      } catch (error) {
        console.log(error);
      }
    }

    getTracks();
  }, [params.category]);

  return (
    <AudioPlayerProvider tracks={trackList}>
      <Container className="Playlist d-flex flex-column">
        {params.category === 'daily' ? (
          <h3 className="text-light text-center mt-4 mb-3">
            Your daily{' '}<br />
            visions in words
          </h3>
        ) : (
          <h3 className="text-light text-center mt-4 mb-3">Words about {params.category}</h3>
        )}

        <Carousel className="Carousel mb-3" fade controls={false}>
          {carouselImages.map((image, idx) => {
            return (
              <Carousel.Item key={`image-${idx}`} className="Carousel-item">
                <img
                  className="d-block w-100"
                  src={image.path}
                  alt="First slide"
                />
              </Carousel.Item>
            );
          })}
        </Carousel>

        <div className="Playlist-trackList d-flex flex-column flex-grow-1">
          {isLoading ? (
            <div className="Loader-wrapper d-flex justify-content-center align-items-center flex-grow-1">
              <div className="Loader"></div>
            </div>
          ) : (
            <ListGroup as="ul" className="TrackList">
              {trackList.map((trackData, idx) => {
                return (
                  <Track
                    key={`track-${idx}`}
                    trackIdx={idx}
                    trackUrl={`/audio/${trackData.category}/${trackData.filename}`}
                    displayName={trackData.displayName}
                  />
                );
              })}
            </ListGroup>
          )}

          <AudioPlayer />
        </div>

      </Container>
      <Footer />
    </AudioPlayerProvider>
  );
};

export default Playlist;
