import React, { useMemo } from 'react';
import { useAudioPlayerContext } from './AudioPlayerProvider';
import { ChevronDoubleLeft, ChevronDoubleRight, PlayCircle, PauseCircle } from 'react-bootstrap-icons';
import './AudioPlayer.css';

const AudioPlayer = () => {
  const context = useAudioPlayerContext();

  const displayCurrentDuration = useMemo(() => {
    if (context.trackDuration !== null) {
      const duration = converTimeForDisplay(context.trackDuration);

      let minutesDisplay = duration.minutes, secondsDisplay;

      if (duration.seconds < 10) {
        secondsDisplay = '0' + duration.seconds;
      } else {
        secondsDisplay = duration.seconds;
      }

      return `${minutesDisplay}:${secondsDisplay}`;
    }

    return '-:--';
  }, [context.trackDuration]);

  const displayTotalDuration = useMemo(() => {
    if (context.totalTrackDuration !== null) {
      const duration = converTimeForDisplay(context.totalTrackDuration);

      let minutesDisplay = duration.minutes, secondsDisplay;

      if (duration.seconds < 10) {
        secondsDisplay = '0' + duration.seconds;
      } else {
        secondsDisplay = duration.seconds;
      }

      return `${minutesDisplay}:${secondsDisplay}`;
    }

    return '-:--';
  }, [context.totalTrackDuration]);

  return (
    <div className="AudioPlayer d-flex flex-column justify-content-center">
      <div className="AudioPlayer-trackDuration d-flex flex-grow-2 align-items-center pt-3">
        {/* current track duration */}
        <span className="AudioPlayer-duration text-white-50 mx-2">{displayCurrentDuration}</span>
        {/* audio slider - display/seek location in song */}
        <input
          className="AudioPlayer-slider d-flex flex-grow-1"
          type="range"
          min={0}
          step="any"
          max={context.totalTrackDuration || 0}
          onMouseDown={context.handleSeekMouseDown}
          onChange={context.handleSeekChange}
          value={context.trackDuration || 0}
          onMouseUp={context.handleSeekMouseUp}
          />
        {/* total track duration */}
        <span className="AudioPlayer-duration text-white-50 mx-2">{displayTotalDuration}</span>
      </div>

      <div className="AudioPlayer-controlsBox d-flex align-items-center justify-content-around flex-grow-1">
        {/* previous track */}
        <button className="AudioPlayer-controlBtn AudioPlayer-controlBtn--previous text-success" onClick={context.onPrevTrack}>
          <ChevronDoubleLeft className="AudioPlayer-controlIcon" />
        </button>
        {context.isPlaying ? (
          // pause track
          <button className="AudioPlayer-controlBtn AudioPlayer-controlBtn--pause text-success" onClick={context.onPause}>
            <PauseCircle className="AudioPlayer-controlIcon" />
          </button>
        ) : (
          // play track
          <button className="AudioPlayer-controlBtn AudioPlayer-controlBtn--play text-success" onClick={context.onPlay}>
            <PlayCircle className="AudioPlayer-controlIcon" />
          </button>
        )}
        {/* next track */}
        <button className="AudioPlayer-controlBtn AudioPlayer-controlBtn--next text-success" onClick={context.onNextTrack}>
          <ChevronDoubleRight className="AudioPlayer-controlIcon" />
        </button>
      </div>
    </div>
  );
};

export default AudioPlayer;

const converTimeForDisplay = (duration) => {
  // let minutesDisplay, secondsDisplay = '';

  const minutes = Math.floor(duration / 60);
  const seconds = Math.floor(duration - minutes * 60);

  return { minutes, seconds };

  //   minutesDisplay = minutes;

  // if (remainingSeconds < 10) {
  //   secondsDisplay = '0' + remainingSeconds;
  // } else {
  //   secondsDisplay = remainingSeconds;
  // }

  // return `${minutesDisplay}:${secondsDisplay}`;
};