import React from 'react';
import { useAudioPlayerContext } from '../AudioPlayer/AudioPlayerProvider';
import { ListGroup } from 'react-bootstrap';
import { Play, Pause } from 'react-bootstrap-icons';
import './Track.css';

const Track = (props) => {
  const context = useAudioPlayerContext();

  const handlePlayClick = () => {
    if (context.trackUrl !== props.trackUrl) {
      // loading new song
      context.setTrackIdx(props.trackIdx);
      context.setTrackUrl(props.trackUrl);
    } else {
      context.onPlay();
    }
  };

  const isActive = context.trackUrl === props.trackUrl ? 'border-success' : 'border-dark';

  return (
    <ListGroup.Item as="li" className={`Track d-flex mb-1 py-3 align-items-center text-light bg-dark border ${isActive}`}>
      {(context.isPlaying && context.trackUrl === props.trackUrl) ? (
        <button className="Track-controlBtn mr-4" onClick={() => context.onPause()}>
          <Pause className="Track-icon text-success" />
        </button>
      ) : (
        <button className="Track-controlBtn mr-4" onClick={handlePlayClick}>
          <Play className="Track-icon text-success" />
        </button>
      )}

      <span className="Track-displayTitle flex-grow-1">
        Poem: <span className="Track-title">{props.displayName}</span>
      </span>

      {/* <span className="Track-duration mr-sm-5 mr-3">
        {props.duration || '00:00'}
      </span> */}
    </ListGroup.Item>
  );
};

export default Track;
